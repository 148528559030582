'use client'

import {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useMachine } from '@xstate/react'
import { twJoin, twMerge } from 'tailwind-merge'

import { useClickAway } from '@/common/hooks/use-click-away'
import { useTabAway } from '@/common/hooks/use-tab-away'
import {
  AutocompleteEvent,
  AutocompleteProps,
  AutocompleteState,
} from './autocomplete-types'
import { AutocompleteProvider } from './context'
import { autocompleteMachine } from './machine'
import { AutocompleteMenu } from './menu'
import { IconButton } from '../icon-button'
import { SearchIcon } from '../icons/svg'
import { Input } from '../input'
import { selectors } from '@/common/constants/selectors-constants'

export const Autocomplete = (props: AutocompleteProps) => {
  const {
    items,
    placeholder,
    placeholderFocused = placeholder,
    loading,
    onChange,
    onSubmit,
    onSelect,
    className,
  } = props

  // refs
  const wrapperRef = useRef<HTMLDivElement>(null)
  const localInputRef = useRef<HTMLInputElement>(null)

  // xstate
  const [state, send] = useMachine(autocompleteMachine, {
    context: {
      items,
    },
    actions: {
      onChange: (_, event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value)
      },
      onReset: () => {
        onChange?.('')
      },
      onSubmit: (context) => {
        const { searchValue, focusedItemId } = context
        if (focusedItemId) {
          const item = context?.items?.find((item) => item.id === focusedItemId)
          if (item) {
            onSelect?.(item)
          }
        } else {
          onSubmit?.(searchValue)
        }
      },
    },
  })
  const { events$ } = state.context

  const isMenu = !state.matches(AutocompleteState.IDLE)

  const [isSubmitting, setIsSubmitting] = useState(false)

  // effects
  useClickAway(wrapperRef, () => send?.('blur'))
  useTabAway(localInputRef, () => send?.('blur'))

  // keep refs up to date so the machine can access the latest values
  useEffect(() => {
    send('updateItems', { items })
  }, [send, items])

  // handlers
  const handleMenuWrapperClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    localInputRef.current?.focus()
  }

  // universal event handler for all events
  const handleEvent = (event: AutocompleteEvent) => {
    if (event.type === 'keydown' && event['key'] === 'Enter') {
      setIsSubmitting(true)
    } else {
      setIsSubmitting(false)
    }

    events$.next(event)
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    send('submit')
  }

  return (
    <div className={twMerge('relative w-full', className)} ref={wrapperRef}>
      <AutocompleteProvider value={state.context}>
        <div className="flex flex-1">
          {loading && (
            <div className="absolute right-2 items-center" aria-label="loading">
              ...
            </div>
          )}
          <IconButton
            onClick={handleSubmit}
            variant="none"
            size="sm"
            className="flex absolute right-0 top-0 w-7 h-full items-center justify-center bg-black text-white aria-[busy=true]:bg-secondary aria-disabled:cursor-default"
            aria-busy={
              isSubmitting && state.matches(AutocompleteState.FOCUSED_SEARCHING)
            }
            aria-label="reset"
            aria-disabled={!state.context.searchValue}
          >
            <SearchIcon className="w-5 h-5" />
          </IconButton>
          <Input
            className={twJoin(
              'w-full',
              'focus:placeholder:italic',
              'aria-[busy=true]:border-secondary',
            )}
            id="autocompleteInput"
            data-test={selectors.common.autocomplete.input}
            role="combobox"
            aria-autocomplete="list"
            variant="autocomplete"
            aria-expanded={isMenu}
            autoComplete="off"
            ref={localInputRef}
            value={state.context.searchValue}
            placeholder={isMenu ? placeholderFocused : placeholder}
            onChange={handleEvent}
            onFocus={handleEvent}
            onKeyDown={handleEvent}
            aria-busy={
              isSubmitting && state.matches(AutocompleteState.FOCUSED_SEARCHING)
            }
          />
        </div>

        <div
          onClick={handleMenuWrapperClick}
          className="flex flex-1 -mt-[2px]"
          role="listbox"
          aria-label="products finder"
        >
          <div className="hidden md:block w-8" />
          {isMenu && <AutocompleteMenu />}
        </div>
      </AutocompleteProvider>
    </div>
  )
}
