import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Text } from '@/components/text'
import { HeaderDesktopNavigationPopup } from './header-desktop-navigation-popup'
import { useIsActiveCategory } from '../../utils'
import { HeaderNavItem } from '../navigation-types'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'

interface HeaderDesktopNavigationProps {
  items?: HeaderNavItem[]
}

export const HeaderDesktopNavigation = ({
  items,
}: HeaderDesktopNavigationProps) => {
  const { isActiveCategory } = useIsActiveCategory()

  return (
    <ul
      data-test={selectors.common.header.menu}
      id="desktop-nav"
      className="relative hidden md:flex md:flex-wrap w-full gap-x-6 lg:gap-x-[1.85rem] mt-3"
    >
      {items?.map((item: HeaderNavItem) => (
        <li
          className={twMerge(
            'group',
            isActiveCategory(item) &&
              'border-b-solid border-secondary border-b-[3px]',
          )}
          key={item.href}
        >
          {item.href.includes('blog') ? (
            <Link
              className="no-underline hover:no-underline leading-[53px]"
              href={item.href}
            >
              <Text className="lg:text-lg" case="upper" weight="bold">
                {item.label}
              </Text>
            </Link>
          ) : (
            <Link
              className="no-underline hover:no-underline leading-[53px]"
              href={item.href}
            >
              <Text className="lg:text-lg" case="upper" weight="bold">
                {item.label}
              </Text>
            </Link>
          )}

          <HeaderDesktopNavigationPopup
            items={item.items}
            isActive={isActiveCategory(item)}
          />
        </li>
      ))}
    </ul>
  )
}
