'use client'

import React from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { CartItemFragment } from '@/api'
import { Alert } from '@/components/alert'
import { Currency } from '@/components/currency'
import { Text } from '@/components/text'
import { useStoreContext } from '@/providers'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'
import {
  DeviceBreakpoints,
  useBrowserMediaQuery,
} from '@/common/hooks/use-browser-media-query'

interface CartItemsProps {
  cartItems: CartItemFragment[]
}

export const CartPopupMenuItems = ({ cartItems }: CartItemsProps) => {
  const t = useTranslations('Header')
  const {
    storeConfig: { currency },
  } = useStoreContext()
  const isLg = useBrowserMediaQuery({
    breakpoint: DeviceBreakpoints.Lg,
  })

  const dynamicHeight = isLg ? Math.floor(window.innerHeight * 0.75) : 320

  return (
    <ol
      className="overflow-y-auto -mr-5 pr-5"
      style={{ maxHeight: `${dynamicHeight}px` }}
    >
      {cartItems.reverse().map((cartItem: CartItemFragment) => {
        return (
          <li
            data-test={selectors.common.card.cardItem}
            className="pb-5 group"
            key={cartItem?.uid}
          >
            <Link
              className="flex"
              href={cartItem?.product?.canonical_url || '#'}
            >
              {cartItem.__typename === 'ConfigurableCartItem' &&
                cartItem?.configured_variant?.image?.url?.small && (
                  <Image
                    src={cartItem?.configured_variant?.image?.url?.small}
                    alt={cartItem?.configured_variant?.image?.label || ''}
                    priority
                    width={67}
                    height={67}
                    className="my-auto mr-4"
                  />
                )}
              {(cartItem.__typename === 'GiftCardCartItem' ||
                cartItem.__typename === 'SimpleCartItem' ||
                cartItem.__typename === 'VirtualCartItem') &&
                cartItem?.product.image?.url?.small && (
                  <Image
                    src={cartItem?.product.image?.url?.small}
                    alt={cartItem?.product.image?.label || ''}
                    priority
                    width={67}
                    height={67}
                    className="my-auto mr-4"
                  />
                )}
              <div>
                <Text
                  className="block group-hover:underline"
                  size="sm"
                  weight="bold"
                >
                  {cartItem?.product?.name}
                </Text>
                {'configurable_options' in cartItem &&
                  cartItem.configurable_options?.map((option) => {
                    if (option?.value_label === 'single_variant') {
                      return null
                    }

                    return (
                      <Text className="block" size="sm" key={option?.id}>
                        {option?.value_label}
                      </Text>
                    )
                  })}
                <Text weight="bold" color="red" size="sm" className="block">
                  <Currency
                    className="block"
                    currency={currency}
                    price={cartItem.prices?.price_including_tax?.value}
                  />
                </Text>
                <Text className="block" size="sm">
                  {t('qty')}: {cartItem.quantity}
                </Text>
              </div>
            </Link>
            {cartItem?.prices?.price_including_tax?.value === 0 && (
              <Alert severity="warning" className="mt-1">
                {t('gift')}
              </Alert>
            )}
          </li>
        )
      })}
    </ol>
  )
}
