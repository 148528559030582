'use client'

import { PropsWithChildren } from 'react'

import {
  DeviceBreakpoints,
  useBrowserMediaQuery,
} from '@/common/hooks/use-browser-media-query'

interface MobileOnlyComponentProps extends PropsWithChildren {
  isServerMobile: boolean
}

export function MobileOnlyComponent({
  children,
  isServerMobile,
}: MobileOnlyComponentProps) {
  const isMd = useBrowserMediaQuery({ breakpoint: DeviceBreakpoints.Md })

  if (isMd ?? isServerMobile) {
    return children
  }

  return null
}
