'use client'

import { PropsWithChildren } from 'react'

import {
  DeviceBreakpoints,
  useBrowserMediaQuery,
} from '@/common/hooks/use-browser-media-query'

interface DesktopOnlyComponentProps extends PropsWithChildren {
  isServerMobile: boolean
}

export function DesktopOnlyComponent({
  children,
  isServerMobile,
}: DesktopOnlyComponentProps) {
  const isMd = useBrowserMediaQuery({ breakpoint: DeviceBreakpoints.Md })

  if (isMd ?? isServerMobile) {
    return null
  }

  return children
}
